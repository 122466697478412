import React from "react";
import Icon1 from "../../images/2.svg";
import Icon2 from "../../images/4.svg";
import Icon3 from "../../images/5.svg";
import Icon4 from "../../images/6.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesP,
} from "./commitmentElements";

const Commitment = () => {
  return (
    <>
      <ServicesContainer id="com">
        <ServicesH1> My Commitment to You </ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1}></ServicesIcon>
            <ServicesP>
              {" "}
              I am here to provide you with expert knowledge and the guidance
              you need to make the best decision for your family.
              {"               "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon2}></ServicesIcon>
            <ServicesP>
              {" "}
              I’ll provide you with a clear insight on pricing, your home's
              condition, the local market, and strategies that will help your
              house sell fast.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon3}></ServicesIcon>

            <ServicesP>
              {" "}
              I will always respond promptly to your calls, emails, and texts,
              ensuring you have no doubts and you’re fully informed every step
              of the way.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon4}></ServicesIcon>

            <ServicesP>
              {" "}
              I’m dedicated to turning your real estate goals into reality,
              providing a smooth, stress-free process from start to finish.{" "}
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Commitment;
