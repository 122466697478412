
import styled from "styled-components";

export const ThankYouMessage = styled.div`
  text-align: center;
  font-size: 20px;
  color: #000;
  width: 60%; /* Full width of parent */
  margin-top: 10%;

  @media screen and (max-width: 420px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;
