import React from "react";
import { animateScroll as scroll } from "react-scroll";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
} from "./FooterElements";
import {
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaPinterest,
  FaTwitter,
} from "react-icons/fa";
import iconTikTok from "../../images/tiktok.svg";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <div className="footer-content">
              <WebsiteRights className="website-rights">
                Noor's Realty {new Date().getFullYear()} All rights reserved.
              </WebsiteRights>
              <SocialIcons className="social-icons">
                <SocialIconLink
                  href="https://x.com/MrsKarinaNoor"
                  target="_blank"
                  aria-label="Twitter"
                >
                  <FaTwitter />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.youtube.com/@RealEstateInMind"
                  target="_blank"
                  aria-label="YouTube"
                >
                  <FaYoutube />
                </SocialIconLink>
                {/* ... other social icons ... */}
                <a
                  href="https://www.tiktok.com/@realestateinmind"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={iconTikTok}
                    alt="TikTok Icon"
                    style={{ width: "50px", height: "50px" }}
                  />
                </a>
              </SocialIcons>
            </div>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
