import React, { useState } from "react";
//import Video from '../../videos/Chain.mp4'
//import Video from '../../videos/Connection.mp4'
//import Video from "../../videos/kids.mp4";
//import Video from '../../videos/Line.mp4'
//import Video from '../../videos/Network.mp4'
//import Video from '../../videos/network2.mp4'
import Video from "../../videos/movie.mp4";
//import LPTLogo from "../../images/LPT.svg"
import { Button } from "../ButtonElements";

import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroSectionElements";

const HeroSection = () => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg autoPlay loop playsInline muted src={Video} type="video/mp4" />
      </HeroBg>
      <HeroContent>
        <HeroH1></HeroH1>
        <HeroH1>Noor's Realty</HeroH1>
        <HeroP>Real Estate Personalized for You</HeroP>
        {/* <HeroBtnWrapper>
          <Button
            to="/contact"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
          >
            Contact Me {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </HeroBtnWrapper> */}
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
