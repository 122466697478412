import styled from "styled-components";

export const ContactContainer = styled.div`
  background: #ffffff;
  height: auto; /* Let the container expand with content */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 30px; /* Adjust padding for smaller screens */
  position: relative;
  z-index: 1;

  @media screen and (max-width: 768px) {
    padding: 20px; /* Less padding on mobile */
  }
`;

export const ContactWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  width: 100%;
  padding: 40px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  align-items: flex-start;

  @media screen and (max-width: 768px) {
    flex-direction: column; /* Stack items on mobile */
    padding: 20px; /* Reduce padding */
  }
`;

export const ContactInfo = styled.div`
  flex: 1;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-right: 0;
    margin-bottom: 20px; /* Add some space between contact info and form */
  }
`;

export const ContactHeading = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
`;

export const ContactDetails = styled.p`
  margin-bottom: 10px;
  font-size: 18px;
  color: #555;
`;

export const ContactForm = styled.form`
  flex: 2;
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure the form takes full width on mobile */

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const FormH1 = styled.h1`
  margin-bottom: 24px;
  color: #333;
  font-size: 24px;
  text-align: center;
`;

export const FormInput = styled.input`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%; /* Full width for input fields */
`;

export const FormTextarea = styled.textarea`
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: none;
  height: 100px;
  width: 100%; /* Full width for textarea */
`;

export const FormButton = styled.button`
  background: #de9539;
  padding: 12px 20px;
  border: none;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 20px; /* Add margin to ensure it's not hidden */

  &:hover {
    background: #000;
    transition: all 0.3s ease-in-out;
  }

  @media screen and (max-width: 768px) {
    width: 100%; /* Ensure it takes full width on mobile */
  }
`;


export const ContactMap = styled.div`
  margin-top: 20px;
  iframe {
    width: 100%;
    height: 200px;
    border: 0;
    border-radius: 10px;

    @media screen and (max-width: 768px) {
      height: 150px; /* Smaller height for the map on mobile */
    }
  }
`;
