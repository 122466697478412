import styled from 'styled-components';
import {Link} from 'react-router-dom'

// export const FooterContainer = styled.footer `
//     background-color: #101522;

// `;
export const FooterContainer = styled.footer`
  background-color: #010606;
  //   padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
//   height: 80px;
  color: #fff;
  position: relative;
  bottom: 0;
  width: 100%;
  z-index: 1;
  margin-bottom: 0; /* Add this line */
//   box-shadow: none;

  @media screen and (max-width: 768px) {
    height: auto;
    padding: 16px;
  }
`;

export const MainContainer = styled.div`
  min-height: calc(100vh - 80px); /* Adjust for footer height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const FooterWrap = styled.div `
    // padding: 48px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1100px;
    // margin: 0 auto;
`

export const FooterLinksContainer = styled.div `
    display: flex;
    justify-content: center;

    @media screen and (max-width: 820px) {
        padding-top: 32px;
    }
`;

export const FooterLinksWrapper = styled.div `
    display: flex;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const FooterLinkItems = styled.div `
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: left;
    width: 160px;
    box-sizing: border-box;
    color: #fff;

    @media screen and (max-width: 420px) {
        margin: 0;
        padding: 10px;
        width: 100%;
    }
`;

export const FooterLinkTitle = styled.h1 `
    font-size: 14px;
    margin-bottom: 16px;
`;

export const FootLink = styled(Link) `
    color: #fff;
    text-decoration: none;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
        color: #DE9539;
        transition: 0.3s ease-out;
    }
`;

export const SocialMedia = styled.section `
    max-width: 1000px;
    width: 100%;
`;

export const SocialMediaWrap = styled.div `
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1100px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const SocialLogo = styled(Link) `
    color: #DE9539;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-weight: bold;
`

export const WebsiteRights = styled.small `
    color: #fff;
    margin-bottom: 16px;
`

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 240px;
  gap: 5px; /* Add this line for gap between icons */
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;
  margin: 0 0px; /* Add this line for margins between icons */
`;

