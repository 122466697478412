import React from "react";
import {
  MobileNavbarContainer,
  Icon,
  CloseIcon,
  MobileMenuWrapper,
  MobileMenu,
  MobileNavLink,
} from "./MobileNavbarElements";

const MobileNavbar = ({ isOpen, toggle }) => {
  return (
    <MobileNavbarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <MobileMenuWrapper>
        <MobileMenu>
          <MobileNavLink
            to="home"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle} // Ensure the menu closes when clicking a link
          >
            Home
          </MobileNavLink>
          <MobileNavLink
            to="aboutme"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            About
          </MobileNavLink>
          <MobileNavLink
            to="com"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            My Commitment
          </MobileNavLink>
          <MobileNavLink
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Services
          </MobileNavLink>
          <MobileNavLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-80}
            onClick={toggle}
          >
            Contact
          </MobileNavLink>
        </MobileMenu>
      </MobileMenuWrapper>
    </MobileNavbarContainer>
  );
};

export default MobileNavbar;
