import styled from 'styled-components';
import {Link as LinkRouter} from 'react-router-dom';

export const Button = styled(LinkRouter)`
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#DE9539" : "#DE9539")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#000" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: ${({ primary }) => (primary ? "#000" : "#DE9539")};
    color: #fff;
  }
`;