import React from 'react'
import Signup from '../components/SignUp'
import ScrollToTop from '../components/ScrollToTop';

const SingupPage = () => {
    return (
        <div>
            <ScrollToTop />
            <Signup />
        </div>
    )
}

export default SingupPage;
