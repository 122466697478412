export const homeObjOne = {
  id: "aboutme",
  lightBg: false,
  lightText: true,
  lightTextDesc: false,
  // topLine: "About Me",
  headLine: <span style={{ color: "#de9539", marginBottom: "0px", marginTop: "100px" }}>About Me</span>,
  description: (
    <>
      <p>
        From a young age, I was inspired by the idea of building something
        meaningful. Growing up in a hardworking immigrant family, I learned that
        success comes from honesty, integrity, and keeping your word. My father,
        a contractor, introduced me to construction, sparking my passion for
        real estate.
      </p>
      <br></br>
      <p>
        Even while thriving in my software career, real estate was always on my
        mind. Eventually, I followed my heart and made the leap into real estate
        full-time.
      </p>
      <br></br>
      <p>
        My tech background sharpened my skills in problem-solving, working under
        pressure, and attention to detail—qualities that now set me apart as a
        Realtor. I blend modern tools with innovative strategies to ensure my
        clients are always ahead. My goal is to make the real estate process
        smooth and stress-free, offering expert guidance every step of the way.
      </p>
      <br></br>
      <p>
        I’m passionate about helping others achieve their real estate dreams. If
        you have questions or need market insights, I’m here as your trusted
        partner, ready to guide you.
      </p>
      <br></br>
      <p>Fun fact: I built this website myself using JavaScript and React!</p>
    </>
  ),
  // buttonLable: "Contact Me",
  imgStart: true,
  img: require("../../images/karinaN.png").default,
  alt: "code",
  dark: true,
  primary: true,
  darkText: false,
};

export const homeObjTwo = {
  id: "com",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  // topLine: "My Commitment To You",
  headLine: "My Commitment To You",
  description: (
    <>
      <p>
        From a young age, I was inspired by the idea of building something
        meaningful. Growing up in a hardworking immigrant family, I learned that
        success comes from honesty, integrity, and keeping your word. My father,
        a contractor, introduced me to construction, sparking my passion for
        real estate.
      </p>
      <br></br>
      <p>
        Even while thriving in my software career, real estate was always on my
        mind. Eventually, I followed my heart and made the leap into real estate
        full-time.
      </p>
      <br></br>
      <p>
        My tech background sharpened my skills in problem-solving, working under
        pressure, and attention to detail—qualities that now set me apart as a
        Realtor. I blend modern tools with innovative strategies to ensure my
        clients are always ahead. My goal is to make the real estate process
        smooth and stress-free, offering expert guidance every step of the way.
      </p>
      <br></br>
      <p>
        I’m passionate about helping others achieve their real estate dreams. If
        you have questions or need market insights, I’m here as your trusted
        partner, ready to guide you.
      </p>
      <p>Fun fact: I built this website myself using JavaScript and React!</p>
    </>
  ),
  buttonLable: "Learn More",
  imgStart: false,
  img: require("../../images/karinaN.png").default,
  alt: "code",
  dark: false,
  primary: false,
  darkText: true,
};

// export const homeObjThree = {
//   //services prior
//   id: "pricing",
//   lightBg: false,
//   lightText: true,
//   lightTextDesc: false,
//   topLine: "University Code",
//   headLine: "Unbeliabable value for this price",
//   description: "We are hosting 3 sessions a week, two hours a day" + "",
//   buttonLable: "Sign Up for Classes",
//   imgStart: true,
//   img: require("../../images/svg-1.svg").default,
//   alt: "code",
//   dark: true,
//   primary: true,
//   darkText: false,
// };
