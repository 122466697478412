import React, { useState } from "react";
import {
  ContactContainer,
  ContactWrapper,
  ContactInfo,
  ContactHeading,
  ContactDetails,
  ContactForm,
  FormH1,
  FormLabel,
  FormInput,
  FormTextarea,
  FormButton,
  ContactMap,
} from "./ContactElements";
import { ThankYouMessage } from "./ThankYouMessage";

const inputStyles = {
  backgroundColor: "#de9539",
};

const ContactMe = () => {
  const [formSubmitted, setFormSubmitted] = useState(false); // Step 1: Track form submission
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  }); // Form state

    // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior (page reload)

    // Use Fetch to send the form data to Formspree
    fetch("https://formspree.io/f/xvgpegly", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          setFormSubmitted(true); // If successful, show thank-you message
        } else {
          alert("Something went wrong. Please try again.");
        }
      })
      .catch((error) => {
        alert("Error: " + error.message);
      });
  };

  return (
    <ContactContainer id="contact">
      <ContactWrapper>
        <ContactInfo>
          <ContactHeading>Contact Information</ContactHeading>
          <ContactDetails>📞 Phone: (469) 833-6585</ContactDetails>
          <ContactDetails>📧 Email: karina@noorsrealty.com</ContactDetails>
          <ContactMap>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13410.180042543577!2d-96.6988853!3d33.0198433!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c20d66870cf6f%3A0xc31cda978af68fc7!2sPlano%2C%20TX!5e0!3m2!1sen!2sus!4v1649471302897!5m2!1sen!2sus"
              allowFullScreen=""
              loading="lazy"
            />
          </ContactMap>
        </ContactInfo>

        {!formSubmitted ? (
          <ContactForm
            action="https://formspree.io/f/xvgpegly"
            method="POST"
            onSubmit={handleSubmit}
          >
            <FormH1>Questions?</FormH1>
            <FormInput
              type="text"
              id="name"
              name="name"
              placeholder="Your name"
              required
              onChange={handleChange}
            />
            <FormInput
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              required
              onChange={handleChange}
            />
            <FormTextarea
              id="message"
              name="message"
              placeholder="Your message"
              required
              onChange={handleChange}
            />
            <FormButton type="submit">Send Message</FormButton>
          </ContactForm>
        ) : (
          <ThankYouMessage style={inputStyles}>
            Thank you! Your message has been sent.
          </ThankYouMessage>
        )}
      </ContactWrapper>
    </ContactContainer>
  );
};;

export default ContactMe;
