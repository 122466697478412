import React, { useState } from "react";
import NavBar from "../components/Navbar";
import MobileNavbar from "../components/MobileNavbar";
import HeroSection from "../components/HeroSection";
import InfoSection from "../components/InfoSection/index.js";
import Commitment from "../components/Commitment"
import Services from "../components/Services";
import Footer from "../components/Footer";
import ContactMe from "../components/ContactMe"
// import Pricing from "../components/Pricing/Pricing";
import { homeObjOne, homeObjTwo } from "../components/InfoSection/Data";
// import { pricingObj } from "../components/Pricing/PricingData";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavBar toggle={toggle} />
      <MobileNavbar isOpen={isOpen} toggle={toggle} />
      <HeroSection />
      <InfoSection {...homeObjOne} />
      {/* <InfoSection {...homeObjTwo} /> */}
      <Commitment></Commitment>
      <Services></Services>
      <ContactMe></ContactMe>
      {/* <Pricing {...pricingObj} /> */}
      <Footer />
    </>
  );
};

export default Home;
