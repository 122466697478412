import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from './pages';
import SingupPage from './pages/signup';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component ={Home} exact />
        <Route path="/signup" component ={SingupPage} exact />
      </Switch>
    </Router>
  );
}


export default App;
