import React from "react";
import Icon1 from "../../images/8.svg";
import Icon2 from "../../images/7.svg";
import Icon3 from "../../images/3.svg";
import Icon4 from "../../images/1.svg";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./servicesElements";

const Services = () => {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1> Services </ServicesH1>
        <ServicesWrapper>
          <ServicesCard>
            <ServicesIcon src={Icon1}></ServicesIcon>
            <ServicesH2> Buyer's </ServicesH2>
            <ServicesP>
              {" "}
              Tailored solutions for first-time buyers or expanding your
              property portfolio<br></br>
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon2}></ServicesIcon>
            <ServicesH2> Seller's</ServicesH2>
            <ServicesP>
              {" "}
              Weather you're selling your first home or managing multiple
              properties.{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon3}></ServicesIcon>
            <ServicesH2> Life Transitions </ServicesH2>
            <ServicesP>
              {" "}
              Helping sellers through life transitions events such as divorce or
              probate{" "}
            </ServicesP>
          </ServicesCard>

          <ServicesCard>
            <ServicesIcon src={Icon4}></ServicesIcon>
            <ServicesH2> Investments </ServicesH2>
            <ServicesP>
              {" "}
              Help you run numbers for investment properties like rentals,
              FSBO's, and flips!{" "}
            </ServicesP>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
};

export default Services;
